import Link from "next/link";
import React, { useState } from "react";
import { useRouter } from 'next/router';
import { NavigationFilled } from "@fluentui/react-icons";
import Image from "next/image";
import logo from "../assets/logo.png";
import { useShoppingCart } from "use-shopping-cart";

function Navigation() {
    const router = useRouter();
    const [isOpen, setIsOpen] = useState(false);
    const { cartCount } = useShoppingCart();
  
    const isActive = (href: string) => router.pathname === href;
    const isShoppingCartOrProducts = cartCount ?? 0 > 0

  return (
    <div className="navbar absolute z-40 bg-[#454545] sm:p-10">
    <div className="navbar-start sm:w-full xl:w-1/3">
      <div className="dropdown">
        <div tabIndex={0} role="button" className="btn btn-ghost bg-[#454545] text-white lg:hidden" onClick={() => setIsOpen(!isOpen)}>
          <NavigationFilled className="w-8 h-8" />
        </div>
        <ul
          tabIndex={0}
          className={`menu menu-sm dropdown-content bg-black rounded-box z-[1] mt-3 w-52 p-2 shadow ${isOpen ? 'block' : 'hidden'}`}>
          <li><Link href="/#landing-section" className={`font-medium text-white  text-xl ${isActive('/#landing-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>Home</Link></li>
          <li>
            <Link href="/#gallery-section" className={`font-medium text-xl text-[#F6F4D2] ${isActive('/#gallery-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>Menu</Link>
          </li>
          <li><Link href="/#faq-section" className={`font-medium text-xl text-[#F6F4D2] ${isActive('/#faq-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>FAQ's</Link></li>
          <li><Link href="/#about-section" className={`font-medium text-xl text-[#F6F4D2] ${isActive('/#about-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>Meet Eduarda</Link></li>
        </ul>
      </div>
      <Link href="/" className="flex flex-row items-center gap-x-6 justify-center">
        
        <div className="sm:hidden">
        <Image src={logo} alt="Logo" width={50} height={50} />
        </div>
        <div className="hidden sm:block ml-4">
        <Image src={logo} alt="Logo" width={75} height={75} />
        </div>
        <h1 className="hidden sm:block sm:text-5xl 2xl:text-6xl font-brand font-light text-white w-64 2xl:w-80 m-0">Dulce Angelina</h1>
      </Link>
    </div>
    <div className="navbar-center hidden lg:flex">
      <ul className="menu menu-horizontal px-1 gap-x-6">
        <li><Link href="/#landing-section" className={`font-semibold text-[#F6F4D2] text-lg  whitespace-nowrap text-ellipsis ${isActive('/#landing-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>Home</Link></li>
        <li><Link href="/#gallery-section" className={`font-semibold text-[#F6F4D2] text-lg  whitespace-nowrap text-ellipsis ${isActive('/#gallery-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>Menu</Link></li>
        <li><Link href="/#faq-section" className={`font-semibold text-[#F6F4D2] text-lg  whitespace-nowrap text-ellipsis ${isActive('/#faq-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>FAQ's</Link></li>
        <li><Link href="/#about-section" className={`font-semibold text-[#F6F4D2] text-lg  whitespace-nowrap text-ellipsis ${isActive('/#about-section') ? 'border-[#DF7790]' : 'border-transparent'} hover:border-[#DF7790]`}>Meet Eduarda</Link></li>
      </ul>
    </div>
    <div className="navbar-end flex-shrink">
      {isShoppingCartOrProducts ? (
        <Link href="/shoppingcart" className="btn btn-primary text-black font-bold rounded-full uppercase sm:mr-4">
          Cart ({cartCount})
        </Link>
      ) : (
        <Link href="/#gallery-section" className="btn btn-primary text-black font-bold rounded-full uppercase sm:mr-4 bg-[#F6F4D2]">
          Order Now
        </Link>
      )}
    </div>
  </div>
  )
}

export default Navigation