import React, { ReactNode } from "react";
import Head from "next/head";
import Link from "next/link";
import Cart from "./Cart";

type Props = {
  children: ReactNode;
  title?: string;
};

const Layout = ({
  children,
  title = "DulceAngelina | Brazilian Cakes",
}: Props) => (
  <>
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@meinbiz" />
      <meta name="twitter:title" content="DulceAngelina | Brazilian Cakes" />
      <meta name="twitter:description" content="Delicious Cakes" />
      <meta
        name="twitter:image"
        content="https://nextjs-typescript-react-stripe-js.vercel.app/social_card.png"
      />
      <link rel="shortcut icon" href="/favicon.ico" />
    </Head>
    <Cart>
      <div className="h-full w-full bg-white">{children}</div>
    </Cart>
    {/* <div className="banner">
      <span>
        This is a{' '}
        <a
          href="https://github.com/stripe-samples"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stripe Sample
        </a>
        .{' View code on '}
        <a
          href="https://github.com/vercel/next.js/tree/canary/examples/with-stripe-typescript"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        .
      </span>
    </div> */}
  </>
);

export default Layout;
