const product = [
  {
  "name":"Chocolate Fudge Sequilho Cake (15 cm diameter)",
  "description":"Delight in the decadence of our Biscuit Cake with Chocolate Ganache, inspired by the traditional Brazilian 'Bolo de Sequilho com Brigadeiro.' This cake features three layers of rich biscuit cake, generously topped with luscious chocolate ganache. It's the perfect indulgence for chocolate lovers and a showstopper at any celebration.",
  "ingredients":"Biscuit, chocolate ganache, and more",
  "id":"sku_Chocolate_15cm",
  "price":6000,
  "image":"https://dulceangelina.com/product-chocolate-fudge.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Orange Cake (15 cm diameter)",
  "description":"Enjoy the zesty delight of our Orange Cake, topped with a refreshing lemon icing. This cake is perfectly tasty and fluffy, making it an ideal treat for any occasion. The combination of orange and lemon creates a burst of citrus flavor that is both invigorating and delicious.",
  "ingredients":"Orange, lemon icing, and more",
  "id":"sku_Orange_15cm",
  "price":2700,
  "image":"https://dulceangelina.com/product-orange-cake.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Orange Cake (20 cm diameter)",
  "description":"Enjoy the zesty delight of our Orange Cake, topped with a refreshing lemon icing. This cake is perfectly tasty and fluffy, making it an ideal treat for any occasion. The combination of orange and lemon creates a burst of citrus flavor that is both invigorating and delicious.",
  "ingredients":"Orange, lemon icing, and more",
  "id":"sku_Orange_20cm",
  "price":3500,
  "image":"https://dulceangelina.com/product-orange-cake.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Cassava Cake (15 cm diameter)",
  "description":"Discover the delightful taste of our Cassava Cake, made from scratch with wholesome, healthy ingredients. This cake features a blend of cassava, coconut, butter, coconut milk, milk, and sugar, resulting in a cozy flavor perfect for any moment. Enjoy the unique texture and taste that only a traditional cassava cake can offer, whether you're sharing it with loved ones or savoring it on your own.",
  "ingredients":"Cassava, coconut, butter, coconut milk, milk, sugar",
  "id":"sku_Cassava_15cm",
  "price":2700,
  "image":"https://dulceangelina.com/product-cassava-cake.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Cassava Cake (20 cm diameter)",
  "description":"Discover the delightful taste of our Cassava Cake, made from scratch with wholesome, healthy ingredients. This cake features a blend of cassava, coconut, butter, coconut milk, milk, and sugar, resulting in a cozy flavor perfect for any moment. Enjoy the unique texture and taste that only a traditional cassava cake can offer, whether you're sharing it with loved ones or savoring it on your own.",
  "ingredients":"Cassava, coconut, butter, coconut milk, milk, sugar",
  "id":"sku_Cassava_20cm",
  "price":3500,
  "image":"https://dulceangelina.com/product-cassava-cake.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Cassava Cake (Party Size)",
  "description":"Discover the delightful taste of our Cassava Cake, made from scratch with wholesome, healthy ingredients. This cake features a blend of cassava, coconut, butter, coconut milk, milk, and sugar, resulting in a cozy flavor perfect for any moment. Enjoy the unique texture and taste that only a traditional cassava cake can offer, whether you're sharing it with loved ones or savoring it on your own.",
  "ingredients":"Cassava, coconut, butter, coconut milk, milk, sugar",
  "id":"sku_Cassava_Party",
  "price":4500,
  "image":"https://dulceangelina.com/product-cassava-cake.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Banana Bread (20 cm x 10 cm)",
  "description":"Made with whole ripened bananas, cinnamon, and warming spices, this delicious loaf can be served fresh or toasted. Experience the ultimate comfort food with our Banana Bread, featuring chocolate chips for an extra touch of sweetness. This super fluffy and buttery banana bread is made with high-quality ingredients, making it perfect for any season. Whether you're enjoying it for breakfast, a snack, or dessert, it's sure to bring joy to your taste buds.",
  "ingredients":"Whole ripened bananas, cinnamon, warming spices, chocolate chips, butter, and more",
  "id":"sku_Banana_20x10",
  "price":2500,
  "image":"https://dulceangelina.com/product-bannana-bread.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Churros Cake (20 cm diameter)",
  "description":"Indulge in our Churros Cake, a delightful combination of cinnamon and handcrafted dulce de leche. This cake brings together the warm, comforting flavors of cinnamon and the rich sweetness of dulce de leche, perfect for satisfying your dessert cravings. Available in a 20 cm diameter or in a charming metal pan, this cake is sure to impress.",
  "ingredients":"Cinnamon, handcrafted dulce de leche, and more",
  "id":"sku_Churros_20cm",
  "price":3800,
  "image":"https://dulceangelina.com/product-churros-cake.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Bem-celebration (200g)",
  "description":"Our Bem-celebration is a cherished favourite at Brazilian festivities, showcasing a Brazilian sponge cake filled with delightful handmade dulce de leche.",
  "ingredients":"none",
  "id":"sku_bem_none",
  "price":500,
  "image":"https://dulceangelina.com/product-bem-celebration.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Coconut and Chocolate (200g)",
  "description":"Savor the rich, decadent taste of our Dark Chocolate with Coconut Biscuits. These round biscuits, each 5 cm in diameter, combine the deep flavor of dark chocolate with the tropical sweetness of coconut, creating a luxurious treat that's hard to resist.",
  "ingredients":"none",
  "id":"sku_coconut_none",
  "price":1400,
  "image":"https://dulceangelina.com/product-coconut-choclate.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Chocolate Drop (none)",
  "description":"Savor the rich, decadent taste of our Dark Chocolate with Coconut Biscuits. These round biscuits, each 5 cm in diameter, combine the deep flavor of dark chocolate with the tropical sweetness of coconut, creating a luxurious treat that's hard to resist.",
  "ingredients":"none",
  "id":"sku_chocolate_none",
  "price":1400,
  "image":"https://dulceangelina.com/product-chocolate-drop.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Milky Buttery Biscuits (200g)",
  "description":"Experience the comforting simplicity of our Milky Buttery Biscuits, made with a rich buttery flavor that melts in your mouth with every bite.",
  "ingredients":"Butter, Sugar, Milk, Salt, Flour, Dark chocolate, Coconut",
  "id":"sku_milky_none",
  "price":1500,
  "image":"https://dulceangelina.com/product-milk-buttery.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Cinnamon Biscuits (none)",
  "description":"none",
  "ingredients":"Butter, Sugar, Milk, Salt, Flour, Brazilian guava jam",
  "id":"sku_cinnamon_none",
  "price":700,
  "image":"https://dulceangelina.com/product-cinnamon.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Parmesan Medallion (200g)",
  "description":"Indulge in our handcrafted Parmesan Medallion biscuits, a perfect blend of buttery goodness and savory Parmesan cheese. These biscuits are ideal for pairing with coffee, morning tea, cheese platters, or as a thoughtful gift for a friend. Each bite offers a delightful balance of flavors that will leave you craving more.",
  "ingredients":"Butter, sugar, milk, salt, flour, Parmesan cheese",
  "id":"sku_parmesan_none",
  "price":1400,
  "image":"https://dulceangelina.com/product-parmasen-meddalion.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Guava Sweet (200g)",
  "description":"Indulge in the sweet and tangy flavors of our Brazilian Guava Biscuits, crafted with care using a buttery biscuit base and filled with luscious Brazilian guava jam. These biscuits offer a delightful combination of buttery texture and fruity sweetness, perfect for a taste of Brazil in every bite.",
  "ingredients":"Butter, Sugar, Milk, Salt, Flour",
  "id":"sku_guava_none",
  "price":1400,
  "image":"https://dulceangelina.com/product-guava-sweet.png",
  "attribution":"",
  "currency":"AUD"
  },
  {
  "name":"Brazilian Lava Carrot Cake (20cm)",
  "description":"Immerse yourself in the delightful world of our Brazilian Lava Carrot Cake—a captivating twist on the classic, beloved by those who gather for afternoon teas and friendly get-togethers across Brazil.",
  "ingredients":"Carrots, Oil, Sugar, Eggs, Condensed milk, 40% cocoa chocolate, Chocolate powder, Baking powder",
  "id":"sku_brazilian_none",
  "price":3800,
  "image":"https://dulceangelina.com/product-brazilian-lava-cake.png",
  "attribution":"",
  "currency":"AUD"
  }
  ]
export default product
